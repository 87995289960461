/**
 * LM2 強制解綁舊用戶門號
 * https://lmliff.cht.com.tw/unbind-mobile
 */
import { useCallback, useMemo } from 'react';

import _ from 'lodash';

import { usePostMobileUnLinkOldUser } from '@/apis/hooks/usePostMobileUnLinkOldUser';
import imgUnbind from '@/assets/images/img-unbind.svg';
import { CancelButton, PrimaryButton } from '@/components/StandardButton';
import useLiff from '@/hooks/useLiff';
import ModuleFlowResult from '@/modules/ModuleFlowResult';
import SVG from '@/patterns/SVG';
import { PropTypes } from '@/utils/propTypes';
import { styled } from '@/utils/styledComponents';

const title = '是否解除原LINE帳號之綁定狀態?';
const message = '';

const StyledImage = styled(SVG)`
  margin-top: 22px;
  margin-bottom: 32px;
`;

const renderImage = () => {
  return <StyledImage src={imgUnbind} />;
};

const SecondButton = styled(CancelButton)`
  font-weight: 500;
  margin-bottom: 16px;
`;

/**
 * 強制解綁舊用戶門號頁面
 */
const UnbindMobilePage = (
  /** @type {{location: {state: { mobile: string, otp: string}}}} */ {
    location,
  }
) => {
  const { state } = useMemo(
    () =>
      (typeof window !== 'undefined' && location) ?? {
        state: {},
      },
    [location]
  );
  /** @type {string} */
  const mobile = useMemo(() => _.get(state, 'mobile'), [state]);
  /** @type {string} */
  const otp = useMemo(() => _.get(state, 'otp'), [state]);

  const { mutate: unLinkMobileOldUser } = usePostMobileUnLinkOldUser();
  const { quitLiff } = useLiff();
  /**
   * onConfirmToGo 確認按鈕
   * 解除綁定，成功後退出liff
   */
  const onConfirmToGo = useCallback(() => {
    unLinkMobileOldUser(
      { mobile, otp },
      {
        onSuccess: () => {
          quitLiff();
        },
      }
    );
  }, [mobile, otp, unLinkMobileOldUser, quitLiff]);
  /**
   * onConfirmToBack
   * 取消按鈕:離開LIFF
   */
  const onConfirmToBack = useCallback(() => {
    quitLiff();
  }, [quitLiff]);
  const renderButtons = () => (
    <>
      <SecondButton onClick={onConfirmToGo}>是，解除綁定</SecondButton>
      <PrimaryButton type="primary" onClick={onConfirmToBack}>
        否，維持綁定
      </PrimaryButton>
    </>
  );
  return (
    <ModuleFlowResult
      title={title}
      message={message}
      renderImage={renderImage}
      renderButtons={renderButtons}
    />
  );
};
UnbindMobilePage.propTypes = {
  location: PropTypes.object,
};
export default UnbindMobilePage;
